
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";

export default {
  name: "EDataTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected", "saveElement"],

  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const officeList = computed(() => store.getters.Offices);
    const contactTable = computed(() => store.getters.EconomicData);
    const dataTable = computed(() => store.getters.EconomicDataPTD);
    /* const getOffices = () => {
      ApiService.query(`/api/offices/lists`, {}).then(({ data }) => {
        officeList.value = data.offices;
      });
    }; */

    const headers = ref([
      { label: "identifier", prop: "key", required: true },
      { label: "itype", prop: "type", required: true },
      { label: "applies", prop: "applies", required: false },
      { label: "ioffice", prop: "office_id", required: false },
      { label: "iPaymentsDays", prop: "payment_days", required: false },
    ]);
    const headerTable = ref(headers.value);

    const getData = () => {
      //
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });

    const createCommunicationList = (data) => {
      //
    };

    onMounted(() => {
      //getOffices();
      getData();
    });

    return {
      officeList,
      elements,
      contactTable,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      createCommunicationList,
    };
  },
};
