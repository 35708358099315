
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default {
  name: "EDPaymentTypeDetailTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Boolean,
      default: false,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createElement", "removeElement", "saveElement"],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const dataTable = computed(() => store.getters.EconomicDataPTD);
    const paymentTypeDetailId = computed(() => store.getters.EconomicDataPTDID);

    const headers = ref([
      { label: "edataKey", prop: "edataId" },
      { label: "iname", prop: "name" },
      { label: "iPaymentsTypes", prop: "payment_type_id" },
      { label: "first_payment_deferral", prop: "first_payment_deferral" },
      { label: "ifrequency", prop: "frequency" },
      { label: "payments_number", prop: "payment_number" },
    ]);
    const headerTable = ref(headers.value);
    const getData = () => {
      //
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;
    const elementsPT = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    const changePTD = (id) => {
      emit("changeElement", id);
    };
    onMounted(() => {
      getData();
      ApiService.get("/api/paymentTypeDetails/lists?per_page=1000").then(
        function (response) {
          elements.value.options = response.data.payment_type_details;
        }
      );
      ApiService.get("/api/paymentTypes/lists?per_page=1000").then(function (
        response
      ) {
        elementsPT.value.options = response.data.payment_types;
      });
    });

    return {
      elements,
      paymentTypeDetailId,
      changePTD,
      elementsPT,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
    };
  },
};
