<template>
  <div id="kt_profile_details_view" class="card mb-5 mb-xl-10">
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0"></h3>
      </div>
      <!--end::Card title-->

      <!--begin::Action-->
      <button
        v-if="canUpdate"
        class="btn btn-custom btn-app-custom align-self-center"
        @click="saveAll"
      >
        {{ $t("btnSave") }}
      </button>
      <!--end::Action-->
    </div>
    <!--begin::Card body-->
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-4">
            <EDataTE
              :editable="false"
              @createElement="createData"
              @saveElement="saveElement"
              @updateElement="updateElement"
              @fieldSelected="getPaymentTypeDetail"
              @removeElement="removeData"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-4">
            <EDPaymentTypeDetailTE
              v-if="paymentTypeDetail.length > 0"
              :disable="true"
              @changeElement="changeElement"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EDataTE from "@/components/entities/tables-editable/EDataTE";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import EDPaymentTypeDetailTE from "@/components/entities/tables-editable/EDPaymentTypeDetailTE";
export default {
  name: "Payments",
  components: { EDPaymentTypeDetailTE, EDataTE },
  props: {
    profile: Object,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const eDataVisible = ref(false);
    const eDataTable = computed(() => store.getters.EconomicData);
    const paymentTypeDetailDefault = ref(1);
    const paymentTypeDetailId = computed(() => store.getters.EconomicDataPTDID);
    const paymentTypeDetail = computed(() => store.getters.EconomicDataPTD);
    const contactData = computed(() => store.getters.Contacts);
    const communicationData = computed(() => store.getters.Communications);
    const sectorData = computed(() => store.getters.Sectors);
    const addressData = computed(() => store.getters.Addresses);
    const officesData = computed(() => store.getters.Offices);
    const entityData = computed(() => store.getters.EntityData);
    const dataTypes = computed(() => store.getters.EntityTypes);
    const eDataId = ref(0);
    const sync = computed(() => store.getters.Sync);

    onMounted(function () {
      getData();
    });

    const saveAll = () => {
      store.commit("setLoadingStatus", true);
      const entitiesModel = [];
      dataTypes.value.forEach(function (value) {
        entitiesModel.push(value.id);
      });
      const addressModel = [];
      console.log("addressData.value", addressData.value);
      addressData.value.forEach(function (value) {
        value.address_types = value.address_types.map((x) => x.id);
        addressModel.push(value.id);
      });
      const contactsModel = [];
      contactData.value.forEach(function (value) {
        contactsModel.push(value.id);
      });
      const officesModel = [];
      officesData.value.forEach(function (value) {
        officesModel.push(value.id);
      });
      const sectorsModel = [];
      sectorData.value.forEach(function (value) {
        sectorsModel.push(value.id);
      });
      const syncModel = [];
      sync.value.forEach(function (value) {
        syncModel.push(value);
      });
      const economicData = [];
      eDataTable.value.forEach(function (value) {
        economicData.push({
          type: value.type,
          office_id: value.office_id,
          payment_days: value.payment_days,
          applies: value.applies,
          payment_type_detail_id: value.payment_type_detail_id,
        });
      });
      const body = {
        name: entityData.value.name,
        legal_name: entityData.value.legal_name,
        identification_type_id: entityData.value.identification_type_id,
        identification: entityData.value.identification,
        email: entityData.value.website,
        entity_types: entitiesModel,
        sectors: sectorsModel,
        offices: officesModel,
        contacts: contactData.value,
        addresses: addressData.value,
        economic_data: economicData,
        synchronize_flags: syncModel,
        communications: communicationData.value,
      };
      ApiService.post("/api/entities/updateAll/" + route.params.id, body).then(
        function (responde) {
          store.commit("setLoadingStatus", false);
        }
      );
    };

    const getData = () => {
      store.commit("setLoadingStatus", true);
      eDataVisible.value = false;
      store.commit("setLoading", false);
      ApiService.get("/api/paymentTypeDetails/lists?per_page=1000").then(
        function (response) {
          paymentTypeDetailDefault.value =
            response.data.payment_type_details[0].id;
        }
      );
      store.commit("setLoading", false);
      ApiService.get("/api/entities/" + route.params.id + "/economicData").then(
        function (response) {
          store.commit("removeEDataPaymentTypeDetails");
          store.commit("restoreEData");
          eDataVisible.value = true;
          response.data.economic_data.forEach(function (value, key) {
            const payment_days = [];
            value.payment_days.forEach(function (pd) {
              payment_days.push(pd.day);
            });
            const applies = [];
            value.applies_to.forEach(function (ap) {
              applies.push(ap.applies_to);
            });
            if (value.office_id) {
              const index = officesData.value.findIndex(
                (i) => i.id === value.office_id
              );
              if (index === -1) {
                officesData.value.push(value.office);
              }
            }
            store.commit("addEData", {
              key: key + 1,
              id: value.id,
              type: value.type,
              office_id: value.office_id,
              payment_days: payment_days,
              applies: applies,
              payment_type_detail_id: value.payment_type_detail_id,
            });
            store.commit("setEDataPaymentTypeDetails", {
              edataId: key + 1,
              id: value.payment_type_detail_id,
              payment_type_id: value.payment_type_detail.payment_type_id,
              name: "",
              payment_number: value.payment_type_detail.payment_number,
              frequency: value.payment_type_detail.frequency,
              first_payment_deferral:
                value.payment_type_detail.first_payment_deferral,
            });
          });
          store.commit(
            "setEDataPaymentTypeDetailsID",
            response.data.economic_data[0].payment_type_detail_id
          );
          eDataId.value = response.data.economic_data[0].id;
          store.commit("setLoadingStatus", false);
        }
      );
    };

    const getPaymentTypeDetail = (id) => {
      store.commit("setLoadingStatus", true);
      eDataId.value = id.id;
      const ptd = [];
      if (id.payment_type_detail_id > 0) {
        if (
          id.payment_type_detail_id !== null ||
          id.payment_type_detail_id !== undefined
        ) {
          ApiService.get(
            "/api/paymentTypeDetails/" + id.payment_type_detail_id
          ).then(function (response) {
            ptd.push(response.data);
            store.commit("setEDataPaymentTypeDetails", ptd);
            store.commit("setLoadingStatus", false);
          });
        }
      }
    };

    const createData = () => {
      eDataTable.value.push({
        key: eDataTable.value.length + 1,
        id: 0,
        type: "C",
        payment_days: [],
        payment_type_detail_id: paymentTypeDetailDefault.value,
      });
      store.commit("setEData", eDataTable);
      store.commit(
        "setEDataPaymentTypeDetailsID",
        paymentTypeDetailDefault.value
      );
      store.commit("setEDataPaymentTypeDetails", {
        edataId: eDataTable.value.length,
        id: 1,
        payment_type_detail_id: undefined,
        name: "",
        payment_number: "",
        frequency: "",
        first_payment_deferral: "",
      });
    };
    const changeElement = (id) => {
      eDataTable.value.forEach(function (value, key) {
        if (id.edataId === key + 1) {
          eDataTable.value[key].payment_type_detail_id = id.id;
          store.commit("setLoading", false);
          ApiService.get(
            "/api/paymentTypeDetails/" +
              eDataTable.value[key].payment_type_detail_id
          ).then(function (response) {
            paymentTypeDetail.value.forEach(function (pValue, pKey) {
              if (id.edataId === pValue.edataId) {
                paymentTypeDetail.value[pKey].payment_number =
                  response.data.payment_number;
                paymentTypeDetail.value[pKey].payment_type_id =
                  response.data.payment_type_id;
                paymentTypeDetail.value[pKey].first_payment_deferral =
                  response.data.first_payment_deferral;
                paymentTypeDetail.value[pKey].frequency =
                  response.data.frequency;
              }
            });
          });
        }
      });
    };
    const updateElement = (element) => {
      const data = {
        entity_id: element.entity_id,
        office_id: element.office_id,
        payment_days: element.payment_days,
        payment_type_detail_id: element.payment_type_detail_id,
        type: element.type,
        applies: [],
      };
      ApiService.put("api/entities/" + route.params.id + "/economicData", {
        data: [data],
      });
    };
    const saveElement = (field) => {
      store.commit("setLoadingStatus", true);
      const data = {
        entity_id: route.params.id,
        office_id: field.office_id,
        payment_type_detail_id:
          field.payment_type_detail_id === 0
            ? paymentTypeDetailId.value
            : field.payment_type_detail_id,
        payment_days: field.payment_days,
        applies: field.applies,
        type: field.type,
      };
      ApiService.post("api/entities/" + route.params.id + "/economicData", {
        data: [data],
      }).then(function (response) {
        const edata = [];
        response.data.economic_data.forEach(function (value) {
          let payment_days = [];
          let applies = [];
          value.payment_days.forEach(function (pd) {
            payment_days.push(parseInt(pd.day));
          });
          value.applies_to.forEach(function (ap) {
            applies.push(ap.applies_to);
          });
          edata.push({
            id: value.id,
            type: value.type,
            office_id: value.office_id,
            payment_days: payment_days,
            applies: applies,
            payment_type_detail_id: value.payment_type_detail_id,
          });
        });

        store.commit("restoreEData");
        getData();
      });
    };
    const removeData = (i) => {
      if (eDataTable.value.length === 0) {
        paymentTypeDetail.value = [];
      } else {
        setTimeout(function () {
          if (eDataTable.value.length === 0) {
            store.commit("removeEDataPaymentTypeDetails");
          }
        }, 1);
      }
      paymentTypeDetail.value.forEach(function (pDetailValue, pDetailKey) {
        if (eDataTable.value[i].key === pDetailValue.edataId) {
          paymentTypeDetail.value.splice(pDetailKey, 1);
        }
      });
      eDataTable.value.splice(i, 1);
    };

    const permissionsTemp = localStorage.getItem("permissions");
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canUpdate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "entities: update") !== -1
      );
    });

    return {
      saveAll,
      eDataVisible,
      paymentTypeDetail,
      eDataTable,
      changeElement,
      saveElement,
      updateElement,
      createData,
      removeData,
      paymentTypeDetailId,
      getPaymentTypeDetail,
      paymentTypeDetailDefault,
      canUpdate,
    };
  },
};
</script>

<style scoped></style>
